import React from "react"
import { Link, graphql } from "gatsby"
import SiteLayout from '../components/SiteLayout'
import PageSEO from "../components/PageSEO"

export default function Articles({ data }) {
  return (
    <SiteLayout>
      <PageSEO title="Articles" />

      <h2>Articles</h2>

      <p>
        This page will contain all the information I have been able to find
        regarding the Genevan Psalter. These articles have been gathered from
        various sources, including books old and recent, websites, magazines,
        and university archives. Most are out of print. Many have been
        transcribed from photocopies.{" "}
        <a href="/articles/owens-intro/">My own introduction</a> is based on
        information gleaned from the rest of these sources, pasted together with
        my own opinions.
      </p>

      <p>Michael E. Owens, March 3, 2009</p>

      <p>
        <em>
          Note: I have many other articles to post, which I will do as I get
          time and permission. If you're interested please email me.
        </em>
      </p>

      <ul>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <li>
            <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          </li>
        ))}
      </ul>
    </SiteLayout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/pages/(articles)/" } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
